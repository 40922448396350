import React, { useEffect } from 'react'
import { withWidth } from '@material-ui/core'
import Header from '../../contents/Header'
import InitUserPage from '../../contents/InitUserPage'
import SignInForm from './SignInForm'
import { isLoggedIn } from '../../utils/auth'
import { navigate } from 'gatsby'

function SignInPage(props) {
  useEffect(() => {
    ;(async () => {
      if (await isLoggedIn()) {
        navigate('/dashboard/')
      }
    })()
  }, [])

  return (
    <div>
      <Header {...props} menuRight={null} />
      <InitUserPage {...props} title="Ingresar">
        <SignInForm {...props} />
      </InitUserPage>
    </div>
  )
}

export default withWidth()(SignInPage)
