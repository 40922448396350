import React, { useState, useEffect } from 'react'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { navigate, Link } from 'gatsby'
import { Auth } from 'aws-amplify'
import { validateFieldUsers } from '../../constants/validator'
import { AlertMessage } from '../../contents/AlertMessage'
import { ButtonSubmit } from '../../contents/Buttons'
import { Input } from '../../contents/Input'
import { traslateMessage } from '../../utils/AwsCodes'
// import { setUser } from '../../utils/auth'

export default function SignInForm(props) {
  const { width } = props
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [msg, setMgs] = useState({})
  let msgErrorField = {}

  useEffect(() => {
    ;(async () => {
      const { state } = props.location
      if (state && state.msg) {
        setMgs(state.msg)
        setOpenAlert(true)
        navigate('/signin/', { replace: true })
      }
    })()
  }, [props])

  const handleSignIn = async event => {
    event.preventDefault()
    setSubmit(true)
    setMgs({})
    setOpenAlert(false)

    if (validateFieldUsers({ email, password }).is_valid) {
      setLoading(true)
      await Auth.signIn({
        username: email,
        password: password,
      })
        .then(response => {
          // const userInfo = {
          //   ...response.attributes,
          //   username: response.username,
          // }
          navigate('/dashboard/', {
            replace: true,
          })
        })
        .catch(error => {
          setMgs({
            type: 'error',
            message: traslateMessage(error),
          })
          setOpenAlert(true)
          setLoading(false)
        })
    }
  }

  const handleClose = () => {
    setOpenAlert(false)
    setMgs({})
  }

  if (submit) {
    msgErrorField = validateFieldUsers({ email, password }).msg
  }

  return (
    <div>
      <AlertMessage msg={msg} openAlert={openAlert} handleClose={handleClose} />
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSignIn}
      >
        <Input
          name="email"
          type="text"
          placeholder="E-mail"
          value={email}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setEmail}
        />
        <Input
          name="password"
          type="password"
          placeholder="Contraseña"
          value={password}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setPassword}
        />
        <Box paddingTop={1}>
          <Grid container>
            <Grid item xs={width === 'xs' ? 12 : true}>
              <Link to="/forgot-password/" className={classes.active}>
                <Typography variant="body2">Recuperar contraseña</Typography>
              </Link>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingRight: '0.5em' }}
              >
                ¿No tienes una cuenta?
              </Typography>
            </Grid>
            <Grid item>
              <Link to="/signup/" className={classes.active}>
                <Typography variant="body2">Registrarte</Typography>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Typography component="div">
          <Box paddingTop={1} textAlign="left">
            <ButtonSubmit
              title="INGRESAR"
              fullWidth={width === 'xs' ? true : false}
              loading={loading}
            />
          </Box>
        </Typography>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    '& > .MuiFormControl-root > label': {
      fontSize: '14px',
    },
  },
  active: {
    color: '#60D957',
    '&:active': {
      color: '#60D957',
    },
    '& > .MuiTypography-body2': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '22px',
    },
    buttonSubmit: {
      marginTop: '3em',
    },
  },
}))
